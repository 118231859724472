<template>
  <b-row v-if="loaded">
    <b-col cols="12">
      <b-card class="card-custom">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <h1>
            {{ $t("dashboard.reports.steps.step1") }}
          </h1>
        </div>
        <div class="card-body">
          <b-form-group
            class="text-left"
            :state="hasError('report', true)"
            :invalid-feedback="hasError('report')"
            :label="$t(`dashboard.reports.form.report.title`)"
            label-for="report"
            :description="$t(`dashboard.reports.form.report.description`)"
          >
            <multiselect
              id="report"
              v-model="form.report"
              :options="
                report.options
                  ? report.options.length > 0
                    ? report.options
                    : []
                  : []
              "
              :required="true"
              :searchable="true"
              :closeOnSelect="true"
              label="text"
              trackBy="value"
              :multiple="false"
              :selectLabel="$t(`campaigns.form.default.select`)"
              :show-labels="false"
              :deselectLabel="$t(`campaigns.form.default.deselect`)"
              :selectedLabel="$t(`campaigns.form.default.selected`)"
              :placeholder="$t(`dashboard.reports.form.report.placeholder`)"
              :disabled="step !== 1"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title" v-html="props.option.text" />
                </div>
              </template>
            </multiselect>
          </b-form-group>
        </div>
        <b-button
          v-if="step === 1 && form.report !== false"
          style="white-space: nowrap; text-align: center;"
          variant="primary"
          size="lg"
          class="font-weight-bolder"
          @click="step++"
        >
          {{ $t("campaigns.form.next") }}
        </b-button>
      </b-card>
    </b-col>
    <b-col cols="12" class="mt-5" v-if="step === 2">
      <b-card class="card-custom">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <h1>
            {{ $t("dashboard.reports.steps.step2") }}
          </h1>
        </div>
        <div class="card-body">
          <b-form-group
            class="text-left"
            :state="hasError('report_date', true)"
            :invalid-feedback="hasError('report_date')"
            :label="$t(`dashboard.reports.form.report_date.title`)"
            label-for="report"
            :description="$t(`dashboard.reports.form.report_date.description`)"
          >
            <b-form-datepicker
              class="form-control"
              id="report_date"
              v-model="form.report_date"
              :today-button="false"
              :reset-button="false"
              hide-header
              :v-bind="$t(`campaigns.form.labels`) || {}"
              :start-weekday="1"
              close-button
              :locale="locale"
              :max="today"
            />
          </b-form-group>
        </div>
        <b-button
          v-if="form.report_date !== '' && file === false"
          style="white-space: nowrap; text-align: center;"
          variant="primary"
          size="lg"
          class="font-weight-bolder"
          @click="downloadReport()"
        >
          {{ $t("dashboard.reports.form.button") }}
        </b-button>
        <span v-if="file !== false">
          <b-button
            v-for="asset in file"
            :key="asset"
            @click="apiCall(asset.url, asset.link)"
            style="white-space: nowrap; text-align: center;"
            variant="outline-primary"
            size="lg"
            class="font-weight-bolder"
          >
            <i class="fas fa-download" />
            {{ asset.link }}
          </b-button>
        </span>
        <br />
        <br />
        <b-button
          @click="resetForm"
          style="white-space: nowrap; text-align: center;"
          variant="primary"
          size="lg"
          class="font-weight-bolder"
        >
          {{ $t("dashboard.reports.form.reload") }}
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Multiselect from "vue-multiselect";

import {
  getReportsTypesByCompany,
  getReport
} from "@/api/controller/reports.api";

import moment from "moment-timezone";
import _ from "lodash";

export default {
  name: "Reports",
  components: {
    Multiselect
  },
  data() {
    return {
      errors: [],
      step: 1,
      form: {
        report: false,
        report_date: ""
      },
      file: false,
      report: {
        options: []
      },
      locale: localStorage.getItem("language"),
      loaded: false
    };
  },
  mounted() {
    getReportsTypesByCompany(localStorage.getItem("managedCompanyId")).then(
      response => {
        _.each(response.data.records, type => {
          this.report.options.push({
            text: this.$t(`dashboard.reports.families.${type.Family}`),
            value: type.Family
          });
        });
        this.loaded = true;
      }
    );
  },
  computed: {
    today() {
      return moment().format();
    }
  },
  methods: {
    resetForm() {
      this.step = 1;
      this.form.report_date = "";
      this.file = false;
      this.form.report = false;
    },
    downloadReport() {
      const stakeholderId = localStorage.getItem("managedCompanyId");
      getReport(stakeholderId, this.form.report.value, {
        date: this.form.report_date
      }).then(response => {
        if (response.data.records.length > 0) {
          this.file = response.data.records;
        } else {
          this.$swal("ATENCIÓN", "No se han encontrado resultados", "warning");
        }
      });
    },
    apiCall(uri, name) {
      this.axios.get(uri).then(response => {
        let a = document.createElement("a");
        a.href =
          "data:" +
          response.data.records.type +
          ";base64," +
          response.data.records.content;
        a.download = name + ".xlsx";
        a.click();
        this.loading = false;
      });
    },
    hasError(field, boolean = false) {
      var result = null;
      var finder = _.find(this.errors, error => error.name === field.name);
      var found = !_.isEmpty(finder);

      if (found) {
        if (boolean) result = false;
        else if (!finder.error) {
          var multiple = 1;
          if (field.type === "datetime") multiple = 2;
          result = this.callGetError(
            "is_required",
            {
              field: _.toLower(
                this.$t(
                  `dashboard.reports.form.${
                    _.find(this.errors, error => error.name === field.name).name
                  }.title`
                )
              )
            },
            multiple
          );
        } else {
          result = this.callGetError(finder.error, {
            field: this.$t(
              `dashboard.reports.form.${
                _.find(this.errors, error => error.name === field.name).name
              }.title`
            ),
            checker: this.$t(
              `dashboard.reports.form.${
                _.find(this.errors, error => error.name === field.name).checker
              }.title`
            )
          });
        }
      }
      return result;
    }
  }
};
</script>
