import Vue from "vue";

const END_POINT = "/controller/reports";

const getReportsTypes = () => Vue.axios.get(`${END_POINT}/types`);
const getReportsTypesByCompany = stakeholderComapnyId =>
  Vue.axios.get(`${END_POINT}/types/${stakeholderComapnyId}`);
const getReport = (stakeholderCompanyId, type, params) =>
  Vue.axios.post(
    `${END_POINT}/${stakeholderCompanyId}/download/${type}`,
    params
  );

export { getReportsTypes, getReport, getReportsTypesByCompany };
